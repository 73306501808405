.mainContainer{
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.topbar{
    width: 100%;
    height: 64px;
}

.content{
    width: 100%;
    height: calc(100vh - 64px);
    overflow-y: auto;
    overflow-x: hidden;
}